
import Vue, { VueConstructor } from "vue";

import OwnershipForm from "./forms/OwnershipForm.vue";
import CrudMixins from "@/mixins/crud";
import Ownership from "@/models/ownership";

import { IFormFuncs, ItemDataTable } from "@/types/form-types";
import { crudService } from "@/services/crud";
import { IParams, ISearchResponse } from "@/types/store-types";
import { numberToMonthName } from "@/utilsDate";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  components: {
    OwnershipForm,
  },
  mixins: [CrudMixins],
  data() {
    return {
      dialog: false,
      results: {} as ISearchResponse<Ownership>,
      headers: [
        {
          text: "Nome da Propriedade",
          tooltip: "Nome registrado da propriedade",
          align: "left",
          value: "name",
        },
        {
          text: "Cidade",
          tooltip: "Município da propriedade",
          align: "center",
          value: "city.name",
        },
        {
          text: "Estado",
          tooltip: "Estado da federação",
          align: "center",
          value: "city.state.name",
        },
        {
          text: "Ref. soja",
          tooltip: "Referência do contrato para soja",
          align: "center",
          value: "deal_soybean_reference",
        },
        {
          text: "Ref. milho",
          tooltip: "Referência do contrato para milho",
          align: "center",
          value: "deal_corn_reference",
        },
        {
          text: "Ref. algodão",
          tooltip: "Referência do contrato para algodão",
          align: "center",
          value: "deal_cotton_reference",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir a propriedade",
          value: "actions",
          sortable: false,
        },
      ] as ItemDataTable[],
    };
  },
  async mounted() {
    this.localLoading = true;
    await this.searchOwnerships();
  },
  computed: {
    headerFiltered(): ItemDataTable[] {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return [
          this.headers[0],
          this.headers[1],
          this.headers[2],
          this.headers[this.headers.length - 1],
        ];
      }
      return this.headers;
    },
  },
  methods: {
    async searchOwnerships(): Promise<void> {
      this.localLoading = true;
      const params: IParams = {
        my: true,
        checkDelete: true,
      };
      this.results = await crudService.getResourceClass(
        Ownership,
        Ownership.getResource(),
        params
      );
      this.localLoading = false;
    },
    openForm(): void {
      this.dialog = true;
    },
    edit(uownership: Ownership): void {
      const form = this.$refs["form"] as IFormFuncs<Ownership>;
      if (form && form.toUpdate) form.toUpdate(uownership);
    },
    confirmDelete(downership: Ownership): void {
      this.setResource("ownership");
      downership.is_deleted = true;
      downership.deleted_date = new Date().toISOString();
      this.update(downership)
        .then(() => {
          this.$alertSuccess("Propriedade deleteda com sucesso!");
          this.removeFromResourceList(this.results.objects, downership);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar a propriedade!");
        });
    },
    toField(item: Ownership): void {
      this.$router.push({
        name: "Talhão",
        params: { ownership_id: String(item.id) },
      });
    },
    monthName(item: string): string {
      return numberToMonthName(item);
    },
  },
});
